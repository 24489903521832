var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-link',{staticClass:"btn btn-primary",staticStyle:{"margin-left":"5px","margin-bottom":"5px"},attrs:{"href":"https://bit.ly/cnno2021_edital","target":"_blank","variant":"primary"}},[_c('feather-icon',{attrs:{"icon":"FileIcon"}}),_vm._v(" Edital ")],1),_c('b-card',[_c('b-card-code',{attrs:{"title":"Horizontal Form With Icons"},scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeHorizontalIcon)+" ")]},proxy:true}])},[_c('validation-observer',{ref:"scientificWorkForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",staticClass:"repeater-form",style:({height: _vm.trHeight}),on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"btn-icon buttonCoAutor",attrs:{"variant":"success","disabled":!_vm.validationTypeWork},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{attrs:{"icon":"PlusCircleIcon","size":"20"}})],1)],1),_c('div',{staticClass:"typeWork"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeWork),expression:"typeWork"}],attrs:{"type":"radio","name":"typeWork","value":"series"},domProps:{"checked":_vm._q(_vm.typeWork,"series")},on:{"click":function($event){return _vm.typeWorkChange()},"change":function($event){_vm.typeWork="series"}}}),_c('label',{attrs:{"for":"series"}},[_vm._v(" relatos de casos e série ")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeWork),expression:"typeWork"}],attrs:{"type":"radio","name":"typeWork","value":"free"},domProps:{"checked":_vm._q(_vm.typeWork,"free")},on:{"click":function($event){return _vm.typeWorkChange()},"change":function($event){_vm.typeWork="free"}}}),_c('label',{attrs:{"for":"free"}},[_vm._v(" Temas Livres ")]),_c('br')]),_vm._l((_vm.coAutor),function(item,index){return _c('b-col',{key:item.id,ref:"col",refInFor:true,attrs:{"id":item.id,"cols":"12"}},[_c('b-button',{staticClass:"btn-icon buttonDeleteCoAutor",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"20"}})],1),_c('b-form-group',{attrs:{"label":"Co-autor","label-for":"hi-coAutor","label-cols-md":"1"}},[_c('validation-provider',{attrs:{"name":"coAutor","vid":"coAutor","rules":"alpha_spaces|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"hi-coAutor","name":"coAutor","state":errors.length > 0 ? false:null,"placeholder":"Nome completo"},model:{value:(_vm.coAutors[index]),callback:function ($$v) {_vm.$set(_vm.coAutors, index, $$v)},expression:"coAutors[index]"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Titulo","label-for":"hi-title","label-cols-md":"1"}},[_c('validation-provider',{attrs:{"name":"title","vid":"title","rules":"required|min:3|max:120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-textarea',{attrs:{"id":"title","name":"title","placeholder":"Digite seu Titulo...","rows":"1","max-rows":"10","state":errors.length > 0 ? false:null},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('br'),_c('span',{attrs:{"id":"countTitleColour"}},[_vm._v(_vm._s(_vm.countTitle)+" / 120")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Resumo","label-for":"hi-text","label-cols-md":"1"}},[_c('validation-provider',{attrs:{"name":"text","vid":"text","rules":"required|min:3|max:2100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Digite seu resumo...","rows":"8","max-rows":"10"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('br'),_c('span',{attrs:{"id":"countTextColour"}},[_vm._v(_vm._s(_vm.countText)+" / 2100")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Mobile","label-for":"hi-file","label-cols-md":"1"}},[_c('validation-provider',{attrs:{"name":"file","vid":"file","rules":"ext:pdf|size:80000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":_vm.fileState,"placeholder":"Selecione o PDF do seu trabalho","drop-placeholder":"Drop file here..."},model:{value:(_vm.file1),callback:function ($$v) {_vm.file1=$$v},expression:"file1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"offset-md":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" Enviar ")])],1)],2)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }